import React from 'react'

function Listings() {
  return (
    <div>
      Listings
    </div>
  )
}

export default Listings
